import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from '@mui/material';

const galleryItems = [
  { id: 1, title: 'Shifnpay', image: './images/assets/clientLogo/logoTwo.png' },
  { id: 2, title: 'Gapshap', image: './images/assets/clientLogo/gapshap.png' },
  { id: 3, title: 'Zcabs', image: './images/assets/clientLogo/zcabs.png' },
  { id: 4, title: 'Jamshedpur Cane Furniture', image: './images/assets/clientLogo/canFurniture.png' },
  { id: 5, title: 'Majd', image: './images/assets/clientLogo/majd.png' },
  { id: 6, title: 'Image 6', image: 'https://via.placeholder.com/300' },
];

export default function Gallery() {
  return (
    <Box sx={{ py: 6, bgcolor: '#f9f9f9' }}>
      <Container>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          Gallery
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          paragraph
        >
          Explore our collection of stunning images.
        </Typography>

        <Grid container spacing={4}>
          {galleryItems.map((item) => (
            <Grid item key={item.id} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  transition: 'transform 0.3s',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              >
             <CardMedia
  component="img"
  height="200"
  image={item.image}
  alt={item.title}
  sx={{
    objectFit: 'contain', // Ensures the entire image is visible without cropping
    backgroundColor: '#f5f5f5', // Optional: Adds a background to improve visuals if the image doesn't fill the area
  }}
/>

                <CardContent>
                  <Typography variant="h6" align="center">
                    {item.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}