import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Box,
} from '@mui/material';

export default function Portfolio() {
  return (
    <div>
      {/* Header */}


      {/* About Section */}
      <Container id="about" sx={{ py: 6, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          About Me
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          I am a passionate developer with expertise in building responsive and
          scalable web applications. I enjoy solving problems and creating
          intuitive user experiences.
        </Typography>
      </Container>

      {/* Projects Section */}
      <Box id="projects" sx={{ py: 6, bgcolor: '#f5f5f5' }}>
        <Container>
          <Typography variant="h4" align="center" gutterBottom>
            Projects
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[1, 2, 3].map((project) => (
              <Grid item key={project} xs={12} sm={6} md={4}>
                <Card>
                  <CardMedia
                    component="img"
                    alt={`Project ${project}`}
                    height="140"
                    image="https://via.placeholder.com/300"
                  />
                  <CardContent>
                    <Typography variant="h6">Project {project}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      A brief description of the project and its key features.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Contact Section */}
      <Container id="contact" sx={{ py: 6, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Get In Touch
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Feel free to reach out for collaborations or just a friendly hello!
        </Typography>
        <Box
          component="form"
          sx={{
            maxWidth: 600,
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TextField label="Your Name" variant="outlined" fullWidth />
          <TextField label="Your Email" variant="outlined" fullWidth />
          <TextField
            label="Your Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
          />
          <Button variant="contained" color="primary" size="large" type="submit">
            Send Message
          </Button>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ bgcolor: '#333', color: '#fff', py: 3, textAlign: 'center' }}>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} My Portfolio. All rights reserved.
        </Typography>
      </Box>
    </div>
  );
}
