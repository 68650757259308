import React from 'react'
import Home from './Home'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ClientLogo from '../components/ClientLogo'
import Services from '../components/Services'
import TestimonialsSection from '../common/TestimonialsSection'
import TestimonialCarousel from '../components/TestimonialCarousel'
// import { Element } from 'react-scroll';

function Dashboard() {
    return (
        <div>

            {/* <Element name="Home"> */}
            <Home />
            {/* </Element>
            <Element name="clientLogo"> */}
            <ClientLogo />
            {/* </Element> */}
            {/* <Element name="Service"> */}
            <Services />
            {/* </Element>
            <Element name="testimonials"> */}
            <TestimonialsSection />
            <TestimonialCarousel/>
            {/* </Element> */}
        </div>
    )
}

export default Dashboard

/* 

            <Home />
            <ClientLogo />
            <Services />
             <TestimonialsSection />
             <TestimonialCarousel />
            <TestimonialsSection />
*/