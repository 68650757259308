import React, { useState } from 'react';
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Typography, Dialog, DialogActions, DialogContent, TextField, DialogTitle } from '@mui/material';

function Home() {
  const [open, setOpen] = useState(false); // State to control the modal visibility
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({}); // State for form validation errors

  // Handle opening and closing of the modal
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Handle form data change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Form validation logic
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
    if (!formData.message) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission (e.g., API request)
      console.log('Form data submitted:', formData);
      handleClose(); // Close the modal after successful submission
    }
  };

  return (
    <Box sx={{ height: '70vh', px: 4 }}>
      <Card sx={{ bgcolor: '#31257B', width: '100%', height: '70vh', borderRadius: '30px', display: 'flex' }}>
        <Box sx={{ flexGrow: 1, width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <CardContent sx={{ pl: 3, display: 'flex', flexDirection: 'column', py: 1 }}>
            <Typography sx={{ color: 'white', fontSize: 50, fontWeight: 'bold' }}>Software Solutions & Maintenance Support</Typography>
            <Typography sx={{ color: 'white', fontSize: 30 }}>Choose The Correct Path</Typography>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
            <Button variant="contained" sx={{ ml: 2, bgcolor: '#019BE2' }} onClick={handleClickOpen}>Get in touch</Button>
            <Button variant="contained" sx={{ ml: 2, bgcolor: '#019BE2' }}>Why Choose Us</Button>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, width: '50%', display: { md: 'flex', lg: 'flex', sm: 'none', xs: 'none' }, p: 2, alignItems: 'center', justifyContent: 'center' }}>
          <CardMedia component="img" sx={{ width: '100%' }} image={"./images/assets/img/asset.png"} alt="Live from space album cover" />
        </Box>
      </Card>

      {/* Modal for Get in Touch Form */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              error={!!errors.message}
              helperText={errors.message}
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary">Cancel</Button>
              <Button type="submit" variant="contained" color="primary">Submit</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Home;
