import React from 'react'
import Dashboard from './pages/Dashboard'
import { Element } from 'react-scroll'
import Header from './components/Header'
import Footer from './components/Footer'
import ScrollProgressIndicator from './common/ScrollProgressIndicator'
import { Route, Routes } from 'react-router-dom'
import Gallery from './pages/Gallery'
import Portfolio from './pages/PortFollio'
import ContactUs from './pages/ContactUs'

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='Gallery/' element={<Gallery />} />
        <Route path='Portfolio/' element={<Portfolio />} />
        <Route path='ContactUs/' element={<ContactUs />} />


      </Routes>
      <Element name="About Us">
        <Footer />
      </Element>

      <ScrollProgressIndicator />
    </div>
  )
}

export default App