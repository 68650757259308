import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { Modal, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const Logo = styled("img")({
  height: "40px",
  position: "absolute",
});

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  message: yup.string().required("Message is required"),
});

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleLogoClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate("/"); // Navigate to the home page
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = ["Gallery", "ContactUs", "About Us", "Portfolio"];

  const handleNavigation = (item) => {
    if (item === "Portfolio") {
      navigate("/portfolio"); // Navigate to Portfolio page
    } else if (item === "Gallery") {
      navigate("/Gallery");
    } else if (item === "ContactUs") {
      navigate("/ContactUs");
    }
  };

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navItems.map((item) => (
          <ScrollLink
            key={item}
            to={item}
            smooth={true}
            duration={500}
            offset={-100}
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={() => handleNavigation(item)}
          >
            <ListItem button>
              <ListItemText primary={item} />
            </ListItem>
          </ScrollLink>
        ))}
      </List>
    </Box>
  );

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    setModalOpen(false); // Close modal after submission
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "white",
          top: 0,
          transition: "box-shadow 0.3s ease-in-out",
          boxShadow: scrolled ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "none",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            px: { xs: 2, sm: 4 },
            py: 1,
          }}
        >
          <Box
            onClick={handleLogoClick}
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Logo src={"./images/assets/img/logo.png"} alt="Logo" />
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 3,
            }}
          >
            {navItems.map((item) => (
              <ScrollLink
                key={item}
                to={item}
                smooth={true}
                duration={500}
                offset={-100}
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => handleNavigation(item)}
              >
                <Typography
                  color="#2E2D81"
                  sx={{
                    fontWeight: 700,
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  {item}
                </Typography>
              </ScrollLink>
            ))}
          </Box>

          <IconButton
            color="black"
            sx={{ display: { md: "none" }, ml: "auto" }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
              variant="contained"
              sx={{
                ml: 2,
                bgcolor: "#019BE2",
                display: { xs: "none", md: "block" },
              }}
              onClick={() => setModalOpen(true)} // Open modal when clicked
            >
              Get in touch
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawer}
      </Drawer>

      {/* Modal for Get in touch form */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="get-in-touch-modal"
        aria-describedby="get-in-touch-modal-description"
      >
        <Box
          sx={{
            width: 400,
            padding: 4,
            backgroundColor: "white",
            borderRadius: "8px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Get in Touch
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  fullWidth
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Message"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  error={!!errors.message}
                  helperText={errors.message?.message}
                />
              )}
            />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
