import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Rating,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  carousel: {
    marginTop: "7%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    overflow: "hidden",
    marginBottom: 40,
  },
  cardContainer: {
    display: "flex",
    transition: "transform 0.5s ease-in-out",
  },
  card: {
    width: "300px",
    borderRadius: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    margin: "0 16px",
    textAlign: "center",
    backgroundColor: "#31257B",
    color: "#fff",
    transition: "all 0.3s ease-in-out",
  },
  centerCard: {
    backgroundColor: "#0056d2", // Blue background
    color: "#fff", // White text
    transform: "scale(1.1)",
    height: "350px",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.4)",
  },

  avatar: {
    width: "80px",
    height: "80px",
    marginBottom: "16px",
  },
  carouselControls: {
    position: "absolute",
    top: "50%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    transform: "translateY(-50%)",
  },
  button: {
    cursor: "pointer",
    fontSize: "2rem",
    color: "#31257B",
    backgroundColor: "#fff",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    userSelect: "none",
  },
}));

const testimonials = [
  {
    name: "John Dev",
    image: "./images/assets/img/user.jpeg",
    testimonial:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    rating: 4,
    feedback: "Great service and very professional!",
  },
  {
    name: "Jane Doe",
    image: "./images/assets/img/user.jpeg",
    testimonial:
      "Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
    rating: 5,
    feedback: "Absolutely loved the experience!",
  },
  {
    name: "John Smith",
    image: "./images/assets/img/user.jpeg",
    testimonial:
      "When an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    rating: 3,
    feedback: "Satisfactory but could improve in some areas.",
  },
  {
    name: "Jane Doe",
    image: "./images/assets/img/user.jpeg",
    testimonial:
      "Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
    rating: 5,
    feedback: "Absolutely loved the experience!",
  },
  {
    name: "John Smith",
    image: "./images/assets/img/user.jpeg",
    testimonial:
      "When an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    rating: 3,
    feedback: "Satisfactory but could improve in some areas.",
  },
  {
    name: "Jane Doe",
    image: "./images/assets/img/user.jpeg",
    testimonial:
      "Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.",
    rating: 5,
    feedback: "Absolutely loved the experience!",
  },
  {
    name: "John Smith",
    image: "./images/assets/img/user.jpeg",
    testimonial:
      "When an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    rating: 3,
    feedback: "Satisfactory but could improve in some areas.",
  },
];

const TestimonialCarousel = () => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <>
      <Typography variant="h4" align="center" gutterBottom>
        Our Client Feedback
      </Typography>
      <Box className={classes.carousel}>
        <Box
          className={classes.cardContainer}
          style={{ transform: `translateX(-${activeIndex * 316}px)` }}
        >
          {testimonials.map((testimonial, index) => (
            <Card
              className={`${classes.card} ${
                index === activeIndex ? classes.centerCard : ""
              }`}
              key={index}
            >
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar  sx={{
    objectFit: 'contain', // Ensures the entire image is visible without cropping
    backgroundColor: '#f5f5f5', // Optional: Adds a background to improve visuals if the image doesn't fill the area
  }} src={testimonial.image} className={classes.avatar} />
                <Typography variant="h6">{testimonial.name}</Typography>
                <Typography variant="body2" gutterBottom>
                  {testimonial.testimonial}
                </Typography>
                <Typography variant="caption">
                  {testimonial.feedback}
                </Typography>
                <Rating
                  value={testimonial.rating}
                  readOnly
                  style={{ marginTop: "8px" }}
                />
              </CardContent>
            </Card>
          ))}
        </Box>

        <Box className={classes.carouselControls}>
          <span className={classes.button} onClick={handlePrev}>
            &#60;
          </span>
          <span className={classes.button} onClick={handleNext}>
            &#62;
          </span>
        </Box>
      </Box>
    </>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <TestimonialCarousel />
  </ThemeProvider>
);

export default App;
